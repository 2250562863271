:root {
  --standarddarkblue: #023047;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #1e8fac;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #7dc4e6;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardshadow: #ddf0f8;
}

.offices-home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 9vw;
  margin-right: 65px;
}

.offices-home-title {
  color: var(--standarddarkblue);
  font-size: 2em;
}

.offices-home-btns-wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2vw;
}

.offices-home-btn {
  padding: 10px 20px;
  width: 100%;
  background-color: var(--standardmediumblue);
  text-decoration: none;
  text-align: center;
  box-shadow: 0px 0px 5px 2px var(--standardshadow);
  margin-bottom: 15px;
  border-radius: 3px;
}

.offices-home-btn:hover {
  background-color: var(--standardmediumbluehover);
}

.offices-home-btn-text {
  color: white;
  font-weight: bold;
  font-size: 1.2em;
}
