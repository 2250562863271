:root {
  --standarddarkblue: #023047;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #1e8fac;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #7dc4e6;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardshadow: #ddf0f8;
}

.homepage-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  align-items: center;
  justify-content: center;
}

.homepage-title {
  color: var(--standarddarkblue);
  font-size: 7ch;
}

.homepage-button-links {
  display: flex;
  flex-flow: row nowrap;
  width: 50%;
  justify-content: space-around;
}

.homepage-users-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--standardyellow);
  padding: 15px 25px;
  width: 35%;
  text-decoration: none;
  box-shadow: 0px 0px 5px 2px var(--standardshadow);
  margin-right: 5px;
  border-radius: 3px;
}

.homepage-users-btn:hover {
  background-color: var(--standardyellowhover);
}

.homepage-offices-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--standardlightblue);
  padding: 15px 25px;
  width: 35%;
  text-decoration: none;
  box-shadow: 0px 0px 5px 2px var(--standardshadow);
  margin-left: 5px;
  border-radius: 3px;
}
.homepage-offices-btn:hover {
  background-color: var(--standardlightbluehover);
}

.people-icon {
  color: var(--standarddarkblue);
}

.homepage-btn-text {
  color: var(--standarddarkblue);
  font-weight: bold;
  margin: 5px;
  text-align: center;
}
