.m-auto {
  margin: auto;
}

.container {
  width: 50%;
  margin: auto;
}

.w-50 {
  width: 50%;
}

.flex {
  width: 100%;
  display: flex;
}

.w-full {
  width: 100%;
}

.fc {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.label {
  /* color: white; */
  font-weight: bold;
}

.px-10 {
  padding: 0 20px;
}

.mt-5 {
  margin-top: 10px;
}

.text-center {
  text-align: center;
}
.main-content-container {
  width: 100% !important;
}

.form-input {
  width: 100% !important;
  box-sizing: border-box;
}

/* /// */

.wrapper {
  width: 100%;
  background-color: #ffd54b;
  position: relative;
  padding: 50px 0;
  display: flex;
}

.wrapper > .text_container {
  width: 15%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: end;
  text-align: right;
}

.text_container > .logo {
  width: 50%;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  margin-bottom: 30px;
  /* display: none; */
}

form > .container {
  width: 80%;
  height: 100%;
  background-color: rgb(244, 244, 244);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  padding: 10px;
}

form {
  display: contents;
}

.big_bold {
  font-size: 40px;
  font-weight: bolder;

  text-shadow: 0px 0px 19px rgba(255, 255, 255, 0.5);
}

.form-wrap {
  margin: 20px;

  width: 50%;
  padding: 10px;
}
.form-wrap > h2,
p {
  margin: 0;
  margin-bottom: 5px;
}

.subheading {
  color: rgb(101, 101, 101);
}

.small-heading {
  color: rgb(101, 101, 101);
  font-size: small;
  margin-top: 5px;
}

.form-input {
  border-color: #dad8d1 !important;
}

.p-0 {
  padding: 0 !important;
}

.eassist {
  margin-top: auto;
  width: 80%;
}
