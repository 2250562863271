:root {
  --standarddarkblue: #023047;
  --standarddarkbluehover: #022536;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #0e819e;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #5aa3c5;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardhovershadow: #ffffff;
  --standardgrey: rgb(128, 128, 128);
  --standardgreyhover: rgb(98, 98, 98);
}
.app {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

/* This container is not a flexbox on purpose */
.main-content-container {
  box-sizing: border-box;
  height: 90vh;
  margin-top: 85px;
  width: 100vw;
}
.col-content-wrapper {
  display: flex;
  flex-direction: row;
  margin-right: 55px;
  margin-top: 2em;
}

.col-content-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  background-color: var(--standardlightblue);
  border-style: solid;
  border-radius: 20px;
  border-color: var(--standardlightblue);
  margin: 25px;
  padding: 5px 5px 30px;
}

.col-card-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.col-title {
  color: var(--standarddarkbluehover);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 65px;
  margin-top: 10vh;
}
.form-container {
  justify-self: center;
  display: flex;
  flex-direction: column;
  width: 35vw;
  height: 75%;
  align-items: center;
  justify-content: center;
  background-color: var(--standardmediumblue);
  color: white;
  font-weight: 600;
  padding: 20px 10px;
  border-radius: 6px;
}

.form-title {
  font-size: 25px;
  margin: 15px;
  text-align: center;
}
.form-title-sm {
  font-size: 20px;
  margin: 15px;
  text-align: center;
}
.form-item {
  width: 72%;
  margin: 7px 0px;
}

.form-input {
  width: 70%;
  border-style: solid;
  border-radius: 6px;
  padding: 6px;
  margin: 0px 0px;
  border-color: var(--standardmediumblue);
  color: var(--standarddarkblue);
  font-weight: bold;
}

.form-input::placeholder {
  color: var(--standardgreyhover);
}

.form-checkbox {
  align-self: center;
  align-items: center;
  margin: 3px;
}

.form-btn {
  background-color: var(--standarddarkblue);
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-style: solid;
  border-color: var(--standarddarkblue);
  border-radius: 6px;
  padding: 7px 18px 7px;
  margin: 15px;
}

.form-btn:hover {
  background-color: var(--standarddarkbluehover);
}

.form-btn-container {
  display: flex;
  flex-direction: row;
}

.form-error-message-text {
  margin-bottom: 1em;
  text-align: center;
}

.form-error-message-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.instructions {
  width: 80%;
  background-color: var(--standarddarkblue);
  color: white;
  margin: 5px;
  border: 3px solid var(--standarddarkblue);
  border-radius: 5px;
  padding: 7px;
  text-align: center;
}

.instructions-title {
  font-size: 18px;
  margin: 15px;
  text-align: center;
}
.instruction-item {
  padding: 8px;
  text-align: left;
}

.table-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  width: 90%;
}

.table {
  width: 100%;
  background-color: white;
  border: 1px solid var(--standarddarkbluehover);
  border-collapse: collapse;
}

.table-heading {
  padding: 3px 10px;
  text-align: left;
  color: var(--standarddarkbluehover);
  font-size: 15px;
  font-weight: bold;
  border: 1px solid var(--standarddarkbluehover);
}

.table-item {
  padding: 8px;
  text-align: left;
  color: var(--standarddarkbluehover);
  font-size: 15px;
  border: 1px solid var(--standarddarkbluehover);
  font-weight: bold;
}

.table-icon-container {
  padding: 3px;
  text-align: center;
  border: 1px solid var(--standarddarkbluehover);
  width: 10%;
  margin: 0px;
}

.table-icon {
  cursor: pointer;
  text-align: center;
  padding: 6px;
}

.twilio-btn {
  background-color: var(--standardorange);
  color: white;
  font-weight: bold;
  cursor: pointer;
  border-style: solid;
  border-color: var(--standardorange);
  border-radius: 6px;
  padding: 7px 18px 7px;
}

.twilio-btn:hover {
  background-color: var(--standardorangehover);
}
.modal-overlay {
  background-color: rgba(255, 255, 255, 0.849);
  width: 100vw;
  height: 200vh;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  opacity: 0.25;
}
.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.modal {
  width: 400px;
  height: 220px;
  background-color: var(--standarddarkbluehover);
  color: white;
  border-radius: 13px;
  padding: 40px;
}

.modal-header {
  height: 50px;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.modal-heading {
  margin: 0;
  padding: 15px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.modal-message {
  padding: 25px;
  font-size: 18px;
  color: white;
  text-align: center;
  font-weight: normal;
}
.modal-btns {
  position: absolute;
  bottom: 20px;
  margin-bottom: 15px;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal-confirm-btn {
  margin: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  color: var(--standarddarkblue);
  background: var(--standardmediumblue);
  transition: all 0.25s ease;
}

.modal-confirm-btn:hover {
  background: var(--standardmediumbluehover);
}

.modal-cancel-btn {
  margin: 10px 10px;
  cursor: pointer;
  font-weight: bold;
  padding: 11px 28px;
  border-radius: 12px;
  font-size: 15px;
  border: none;
  color: var(--standarddarkblue);
  background: var(--standardlightblue);
  transition: all 0.25s ease;
}

.modal-cancel-btn:hover {
  box-shadow: none;
  transform: none;
  background: var(--standardlightbluehover);
}

.standard-btn {
  background-color: var(--standarddarkblue);
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border-style: solid;
  border-color: var(--standarddarkblue);
  border-radius: 6px;
  padding: 7px 18px 7px;
  margin: 10px;
  max-height: 50px;
}

.standard-btn:hover {
  background-color: var(--standarddarkbluehover);
  border-color: var(--standarddarkbluehover);
}

button,
input {
  font: 1em Hind, sans-serif;
  line-height: 1.5em;
}

input {
  color: black;
}

.search-bar {
  display: flex;
}

.search-bar input,
.search-btn,
.search-btn:before,
.search-btn:after {
  transition: all 0.25s ease-out;
}

.search-bar input,
.search-btn {
  width: 3em;
  height: 3em;
}

.search-bar input:invalid:not(:focus),
.search-btn {
  cursor: pointer;
}

.search-bar,
.search-bar input:focus,
.search-bar input:valid {
  width: 100%;
}

.search-bar input:focus,
.search-bar input:not(:focus) + .search-btn:focus {
  outline: transparent;
}

.search-bar {
  margin: auto;
  padding: 1.5em;
  justify-content: center;
  max-width: 30em;
}

.search-bar input {
  background: transparent;
  border-radius: 1.5em;
  box-shadow: 0 0 0 0.4em white inset;
  padding: 0.75em;
  transform: translate(0.5em, 0.5em) scale(0.5);
  transform-origin: 100% 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-bar input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search-bar input:focus,
.search-bar input:valid {
  background: white;
  border-radius: 0.375em 0 0 0.375em;
  box-shadow: 0 0 0 0.1em white inset;
  transform: scale(1);
}

.search-btn {
  background: black;
  border-radius: 0 0.75em 0.75em 0 / 0 1.5em 1.5em 0;
  padding: 0.75em;
  position: relative;
  transform: translate(0.25em, 0.25em) rotate(45deg) scale(0.25, 0.125);
  transform-origin: 0 50%;
}

.search-btn:before,
.search-btn:after {
  content: "";
  display: block;
  opacity: 0;
  position: absolute;
}

.search-btn:before {
  border-radius: 50%;
  box-shadow: 0 0 0 0.2em white inset;
  top: 0.75em;
  left: 0.75em;
  width: 1.2em;
  height: 1.2em;
}

.search-btn:after {
  background: white;
  border-radius: 0 0.25em 0.25em 0;
  top: 51%;
  left: 51%;
  width: 0.75em;
  height: 0.25em;
  transform: translate(0.2em, 0) rotate(45deg);
  transform-origin: 0 50%;
}

.search-btn span {
  display: inline-block;
  overflow: hidden;
  width: 1px;
  height: 1px;
}

/* Active state */
.search-bar input:focus + .search-btn,
.search-bar input:valid + .search-btn {
  background: #155180;
  border-radius: 0 0.375em 0.375em 0;
  transform: scale(1);
}

.search-bar input:focus + .search-btn:before,
.search-bar input:focus + .search-btn:after,
.search-bar input:valid + .search-btn:before,
.search-bar input:valid + .search-btn:after {
  opacity: 1;
}

.search-bar input:focus + .search-btn:hover,
.search-bar input:valid + .search-btn:hover,
.search-bar input:valid:not(:focus) + .search-btn:focus {
  background: #155180;
}

.search-bar input:focus + .search-btn:active,
.search-bar input:valid + .search-btn:active {
  transform: translateY(1px);
}

@media screen and (prefers-color-scheme: dark) {
  input {
    color: black;
  }

  .search-bar input {
    box-shadow: 0 0 0 0.4em white inset;
  }

  .search-bar input:focus,
  .search-bar input:valid {
    background: transparent;
    box-shadow: 0 0 0 0.1em white inset;
  }

  .search-btn {
    background: black;
  }
}

.office-container {
  background-color: transparent;
  display: flex;
  width: 98%;
  height: fit-content;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.copy-and-paste-text {
  margin-top: 0em;
  margin-bottom: 0em;
}

.office {
  display: flex;
  width: fit-content;
  min-height: fit-content;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  border-radius: 2vh;
  margin-bottom: 0.3em;
  margin-right: 0.15em;
  margin-left: 0.15em;
  border: solid white 0.25vh;
}

.office:hover {
  border: solid #155180 0.25vh;
}

.info-container {
  display: flex;
  flex-direction: column;
  border: solid black;
  width: fit-content;
  height: fit-content;
}

.link {
  color: #155180;
  text-decoration: none;
}

.drop-down-container {
  width: 11em;
}

.drop-down-list-item {
  list-style: none;
  margin-bottom: 0.8em;
}

.info-body {
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: fit-content;
  box-sizing: border-box;
  padding: 1em;
  min-width: fit-content;
}

.computer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.computer-container table {
  width: 100%;
}

.computer-container tbody td {
  padding: 1vh;
}

.site__logo {
  margin-bottom: 2rem;
}

.login_title {
  font-size: 2.75rem;
  font-weight: 200;
  margin: 0 0 1rem;
  text-transform: uppercase;
}

.edit-btn {
  width: fit-content;
  height: fit-content;
  margin-bottom: 1vh;
}

#settings-btn {
  font-size: 4vh;
  color: white;
  background-color: transparent;
  width: fit-content;
  height: 1px;
  padding: 0;
  border: 0;
  margin: 0;
  border: transparent;
  cursor: pointer;
}

#settings-label {
  color: white;
  display: block;
}

.edit-delete-btn {
  background-color: #155180;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 2em; */
  height: 2em;
  width: 5em;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 10px;
  height: 10px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.confirm-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 50%;
  margin-top: 2em;
}

.confirm-sc-removal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 12em;
}

.confirm-sc-removal-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sc-removal-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
  margin-top: 2em;
}

.create-edit-office-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.create-edit-office-item {
  margin-bottom: 0.5em;
  width: 50%;
}

.create-computer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5em;
}

.create-computer-item {
  margin-bottom: 0.5em;
}

/* .header-link-item {
  color: white;
  cursor: pointer;
  margin-right: 1em;
  font-size: 1.25em;
  text-decoration: none;
} */

.automation-item {
  color: black;
  cursor: pointer;
}

.drop-down-list {
  padding: 0;
  margin: 0;
  padding-left: 0.5em;
  padding-right: 0.5em;
  background: white;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  color: black;
  font-size: 1em;
  font-weight: 500;
  padding-right: 2em;
  width: 8em;
  height: 20em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  list-style-type: none;
  z-index: 1;
}

.drop-down-edit {
  background: white;
  position: absolute;
  padding: "2em";
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.5);
}

.drop-down-item {
  cursor: pointer;
  color: black;
  font-size: 1em;
  text-decoration: none;
  width: 100%;
  list-style: none;
  margin-bottom: 0.25em;
}

.drop-down-item:hover {
  cursor: pointer;
  color: black;
  background-color: #f3f3f3;
  text-decoration: none;
  width: 100%;
}

/* .main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
  background-color: #00bce2;
  color: white;
} */

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1em;
  color: white;
  background-color: #155180;
  display: inline-block;
  padding: 1em;
  width: 25%;
}

.inputfile + label {
  cursor: pointer;
}

.file-name-text {
  border-left: 1px #155180 solid;
  border-bottom: 1px #155180 solid;
  border-top: 1px #155180 solid;
  height: 3em;
  width: 75%;
}

#castle-image {
  height: 30em;
}

.picker {
  width: 8vw;
}

.query-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.query-container-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 98%;
  margin-left: 3vw;
  margin-right: 3vw;
}

.query-container-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.team-query-container-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 98%;
  margin-left: 3vw;
  margin-right: 3vw;
}

.team-query-container-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}

input.devInput::placeholder {
  color: black;
}

.devInput {
  width: 5vh;
  text-align: center;
}

.spinner {
  animation: spinner infinite 10s linear;
}

.reporting-container-root {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.reporting-container-branch {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.reporting-tile {
  width: 30%;
  display: flex;
  background-color: #e4e3e3;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
  margin-right: 1em;
  margin-left: 1em;
  border-radius: 2em;
  min-width: 30%;
}

#totals-tile {
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.5em;
}

.totals-tile-double-container {
  width: 100%;
  display: flex;
  background-color: #e4e3e3;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 2em;
}

.totals-tile-double {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  align-items: center;
  padding: 1em;
}

.reporting-tile-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #09304e;
}

.reporting-tile-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: bottom;
  width: 100%;
}

.icon-links {
  padding: 0.5em;
  text-decoration: none;
}

#query-icon {
  margin-right: 1em;
}

#query-icon * {
  color: #cd5c5c;
  margin: 0;
}

#calendar-icon {
  margin-left: 1em;
}

#calendar-icon * {
  color: #57c0ea;
  margin: 0;
}

.team-logos {
  color: #09304e;
  padding-top: 1em;
}

.switch-button {
  background: rgba(197, 195, 195, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 120px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: #09304e;
  position: relative;
  padding-right: 120px;
  position: relative;
}
.switch-button:before {
  content: "Team";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 15px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #69c6eb;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

.nclt-indiv-totals {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1em;
}

.m-auto {
  margin: auto !important;
}
