:root {
  --standarddarkblue: #023047;
  --standarddarkbluehover: #022536;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #1e8fac;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #7dc4e6;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardshadow: #ddf0f8;
  --standardgrey: rgb(155, 155, 155);
  --standardgreyhover: rgb(122, 122, 122);
}

.view-user-container {
  display: flex;
  flex-direction: row;
  margin-right: 55px;
  margin-top: 2em;
}

.view-user-half-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  background-color: var(--standardlightblue);
  border-style: solid;
  border-radius: 20px;
  border-color: var(--standardlightblue);
  margin: 25px;
  padding: 5px 5px 30px;
}

.view-user-title {
  color: var(--standarddarkbluehover);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.view-user-card-content-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.view-user-table-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  width: 85%;
}
.view-user-table {
  width: 100%;
  background-color: white;
  border: 1px solid var(--standarddarkbluehover);
  border-collapse: collapse;
}

.view-user-table-heading {
  padding: 15px;
  text-align: left;
  color: var(--standarddarkbluehover);
  font-size: 15px;
  font-weight: bold;
  border: 1px solid var(--standarddarkbluehover);
}

.view-user-table-item {
  padding: 15px;
  text-align: left;
  color: var(--standarddarkbluehover);
  font-size: 15px;
  border: 1px solid var(--standarddarkbluehover);
  font-weight: bold;
}

.view-user-table-icon-container {
  padding: 3px;
  text-align: center;
  border: 1px solid var(--standarddarkbluehover);
  width: 10%;
  margin: 0px;
}

.view-user-table-icon {
  cursor: pointer;
  text-align: center;
  padding: 15px;
}
.view-user-table-icon:hover {
  background-color: var(--standardwhiteshadow);
}

.view-user-btns-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 25px;
}

.view-user-btn {
  background-color: var(--standarddarkblue);
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border-style: solid;
  border-color: var(--standarddarkblue);
  border-radius: 6px;
  padding: 7px 18px 7px;
  margin: 10px;
  max-height: 50px;
}

.view-user-btn:hover {
  background-color: var(--standarddarkbluehover);
  border-color: var(--standarddarkbluehover);
}

.view-user-message {
  text-align: center;
  color: var(--standarddarkbluehover);
  font-size: 14px;
}

.view-user-ul {
  list-style-type: none;
  margin: 1.5vh 0vw;
  padding: 0;
}
