:root {
  --standarddarkblue: #023047;
  --standarddarkbluehover: #022536;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #1e8fac;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #7dc4e6;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardshadow: #ddf0f8;
}

.create-user-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 65px;
  margin-top: 10vh;
}
