.assign-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 55px;
  margin-top: 2em;
}

.assign-cards-wrapper {
  display: flex;
  flex-direction: row;
}

.assign-half-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  background-color: var(--standardlightblue);
  border-style: solid;
  border-radius: 20px;
  border-color: var(--standardlightblue);
  margin: 25px;
  padding: 5px 5px 30px;
}

.assign-title {
  color: var(--standarddarkbluehover);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.assign-card-content-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.assign-checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.assign-checkbox-label {
  color: var(--standarddarkbluehover);
  font-size: 18px;

  text-align: center;
  margin: 5px;
}
.assign-checkbox {
  border-color: var(--standarddarkbluehover);
}

.assign-btn {
  background-color: var(--standarddarkblue);
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border-style: solid;
  border-color: var(--standarddarkblue);
  border-radius: 6px;
  padding: 7px 18px 7px;
  margin: 10px;
  max-height: 50px;
}

.assign-btn:hover {
  background-color: var(--standarddarkbluehover);
  border-color: var(--standarddarkbluehover);
}

.assign-btns {
  margin-top: 25px;
}
