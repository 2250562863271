:root {
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
}
.go-back-container {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  margin-left: 0.75em;
  margin-top: 0.75em;
}

.go-back-arrow {
  background-color: var(--standardyellow);
  border: 1px solid transparent;
  border-radius: 3px;
  box-shadow: rgba(255, 255, 255, 0.4) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans",
    sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin-right: 1em;
  margin-bottom: 1em;
  outline: none;
  padding: 8px 0.8em;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
}

.go-back-arrow:hover {
  background-color: var(--standardyellowhover);
}
