:root {
  --standarddarkblue: #023047;
  --standarddarkbluehover: #022536;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #0e819e;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #5aa3c5;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardshadow: #ddf0f8;
  --standardshadowhover: #eff6fa;
  --standardgrey: rgb(128, 128, 128);
  --standardgreyhover: rgb(98, 98, 98);
  --standardhovershadow: #ffffff;
}
.comm-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 55px;
  margin-top: 2em;
}

.comm-cards-wrapper {
  display: flex;
  flex-direction: row;
}

.comm-half-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  background-color: var(--standardlightblue);
  border-style: solid;
  border-radius: 20px;
  border-color: var(--standardlightblue);
  margin: 25px;
  padding: 5px 5px 30px;
}

.comm-title {
  color: var(--standarddarkbluehover);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.comm-card-content-container {
  display: flex;
  flex-direction: column;
  width: 85%;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.comm-checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.comm-checkbox-label {
  color: var(--standarddarkbluehover);
  font-size: 18px;
  text-align: center;
  margin: 5px;
}

.comm-checkbox {
  border-color: var(--standarddarkbluehover);
}

.comm-btn {
  background-color: var(--standarddarkblue);
  color: white;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border-style: solid;
  border-color: var(--standarddarkblue);
  border-radius: 6px;
  padding: 7px 18px 7px;
  margin: 10px;
  max-height: 50px;
}

.comm-btn:hover {
  background-color: var(--standarddarkbluehover);
  border-color: var(--standarddarkbluehover);
}

.comm-btns {
  margin-top: 25px;
}

.comm-records-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.comm-text-records-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.comm-text-records-container {
  display: flex;
  flex-flow: row wrap;
  max-width: 70%;
  justify-content: center;
  align-items: center;
}

.comm-display-conversation-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  background-color: var(--standardshadow);
  width: 30vw;
  max-height: 80vh;
  border: var(--standardshadow) 3px solid;
  border-radius: 3px;
}

.comm-conversation-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--standardshadowhover);
  color: var(--standardgreyhover);
  font-size: 13px;
  border: var(--standardshadowhover) 3px solid;
  border-radius: 3px;
  max-width: 30vw;
  padding: 10px;
}

.comm-convo-btns-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background-color: var(--standarddarkblue);
  border: var(--standarddarkblue) 3px solid;
  border-radius: 4px;
  width: 100%;
  max-width: 35vw;
  max-height: 50vh;
  padding: 35px 45px;
}
.comm-display-messages-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  background-color: var(--standardshadow);
  width: 100%;
  max-width: 30vw;
  max-height: 70vh;
}

.comm-scrollable {
  overflow: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.comm-message-container {
  display: flex;
  flex-direction: column;
  margin: 10px;
}

.comm-outbound-message-text {
  color: white;
  background-color: var(--standarddarkblue);
  border: var(--standarddarkblue) 1px solid;
  border-radius: 5px;
  align-self: flex-end;
  padding: 5px;
  font-size: 14px;
  margin: 0;
  max-width: 70%;
}

.comm-inbound-message-text {
  color: white;
  background-color: var(--standardmediumblue);
  border: var(--standardmediumblue) 1px solid;
  border-radius: 5px;
  align-self: flex-start;
  padding: 5px;
  font-size: 14px;
  margin: 0;
  max-width: 70%;
}

.comm-outbound-message-datetime {
  color: var(--standardgreyhover);
  font-size: 11px;
  align-self: flex-end;
  margin: 0;
}

.comm-inbound-message-datetime {
  color: var(--standardgreyhover);
  font-size: 11px;
  align-self: flex-start;
  margin: 0;
}

.comm-help-btn {
  margin: 5px;
}
.comm-help-btn:hover {
  cursor: pointer;
}

.comm-text-convo-btn {
  background-color: white;
  color: var(--standarddarkblue);
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  border-style: solid;
  border-color: white;
  border-radius: 6px;
  padding: 14px 36px 14px;
  margin: 4px 15px;
  max-height: 50px;
}

.comm-text-convo-btn:hover {
  background-color: var(--standardshadow);
  border-color: var(--standardshadow);
}
.comm-text-btns-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.comm-text-headings-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75%;
  align-self: center;
  margin: 0px 20px 20px 20px;
}

.comm-text-btn-font {
  font-weight: bold;
}

.comm-convos-heading-font {
  font-weight: normal;
  color: white;
}
