:root {
  --standarddarkblue: #023047;
  --standarddarkbluehover: #022536;
  --standardmediumblue: #219ebc;
  --standardmediumbluehover: #1e8fac;
  --standardlightblue: #8ecae6;
  --standardlightbluehover: #7dc4e6;
  --standardyellow: #ffb703;
  --standardyellowhover: #ebac0d;
  --standardorange: #fb8500;
  --standardorangehover: #e97e03;
  --standardshadow: #ddf0f8;
}

.header-container {
  display: flex;
  justify-content: space-between;
  color: white;
  background-color: var(--standarddarkblue);
  align-items: center;
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 85px;
  top: 0;
}

.header-logo-container {
  align-self: center;
  padding-left: 2em;
}

.header-links-container {
  display: flex;
  height: 100%;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
}

.header-link-text {
  color: white;
  text-decoration: none;
  text-align: center;
}

/* This active style isn't working */
.header-link-active {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6d96aa;
  cursor: pointer;
  width: 33%;
  height: 100%;
}

.header-link-inactive {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  width: 33%;
  height: 100%;
}

.header-link-inactive:hover {
  background-color: var(--standarddarkbluehover);
}

.header-mini-container {
  align-self: center;
  height: 100%;
  width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-mini-icon-background {
  justify-self: flex-end;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-mini-icon-background:hover {
  background-color: var(--standarddarkbluehover);
}

.header-mini-icon-container {
  justify-self: center;
  margin-right: 20px;
  margin-left: 20px;
}

.header-mini-icon {
  cursor: pointer;
}

.header-dropdown-text {
  align-self: center;
  justify-self: center;
  color: white;
  text-align: left;
}

.header-dropdown-item:hover {
  background-color: var(--standarddarkbluehover);
}

.header-dropdown-item {
  width: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
}

.header-dropdown-container {
  position: fixed;
  top: 84px;
  /* right: 5px; */
  height: fit-content;
  width: 200px;
  background-color: var(--standarddarkblue);
  /* box-shadow: 1px 2px 2px 2px gainsboro; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
